import React from 'react'
import Layout from '../components/shared/Layout'
import SEO from '../components/seo'

const Colors = () => {
return (
    
    <Layout>
        <SEO title="Farger"/>

        <div className="wrap" role="document">
            <div className="content">

                <main className="main" id="main">
                    <header className="header">
                        <div className="container">
                            <div className="header__caption">
                                <h1>Farger</h1>
                            </div>
                        </div>
                    </header>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="submenu">
                                    <ul className="submenu__list">
                                        <li className="submenu__item"><a href="#identity-through-colours">Primærfarger</a></li>
                                        <li className="submenu__item"><a href="#finn">Finn Red</a></li>
                                        <li className="submenu__item"><a href="#strikemaster">Strikemaster Pink</a></li>
                                        <li className="submenu__item"><a href="#finlandia">Finlandia Green</a></li>
                                        <li className="submenu__item"><a href="#husk">Husk Yellow</a></li>
                                        <li className="submenu__item"><a href="#pickled">Pickled Blue</a></li>
                                        <li className="submenu__item"><a href="#hippie">Hippie Blue</a></li>
                                        <li className="submenu__item"><a href="#dune">Dune Black</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">

                                <p className="ingress lead">
                                    Fargene er et viktig virkemiddel for å bygge en klar identitet i markedet. Hovedfargen, den dype, rolige burgundervaløren, er den som vil synes mest og oftest. Den utstråler innsikt, trygghet og varme.</p>
                                <p className="lead">
                                    Ellers består paletten av seks avstemte sekundærfarger. Dette skaper variasjon og mangfold.
                                    </p>
                                <p className="lead">
                                    Fargespråket til Saga KL gir opplevelser av jordnærhet, trygghet og soliditet. 
                                    Her er impulsiviteten totalt fraværende. Istedet råder det gjennomtenkte, 
                                    det seriøse og det veloverveide.
                                </p>



                                <hr/>


                                <div id="identity-through-colours" className="section">
                                    <h2>Identitet gjennom farger</h2>
                                    <p>Hovedfargen, den dype, rolige burgundervaløren, er den som vil synes mest og oftest. Den utstråler innsikt, trygghet og varme. </p>

                                    <img className="img-fluid" src="../img/sections/farger/farger_1.jpg" alt="Saga KL Profilmanual Logofarger 900x600"/>
                                </div>

                                <hr/>

                                <div className="section">
                                    <div className="container-fluid no-padding">
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="finn">Finn Red</h2>
                                                <p>
                                                    PMS: 7642<br/>
                                                    CMYK: 0-86-5-64<br/>
                                                    RGB: 115-46-74<br/>
                                                    HTML: 732E4A
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_2.jpg" alt="Saga KL Profilmanual Logofarge Finn red 900x600"/>
                                            </div>
                                        </div>
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="strikemaster">Strikemaster Pink</h2>
                                                <p>
                                                    PMS: 7646<br/>
                                                    CMYK: 33-61-26-0<br/>
                                                    RGB: 165-110-135<br/>
                                                    HTML: A56E87
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_3.jpg" alt="Saga KL Profilmanual Logofarge Strikemaster Pink 900x600"/>
                                            </div>
                                        </div>
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="finlandia">Finlandia Green</h2>
                                                <p>
                                                    PMS: 5615<br/>
                                                    CMYK: 52-16-52-54<br/>
                                                    RGB: 94-116-97<br/>
                                                    HTML: 5E7461
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_4.jpg" alt="Saga KL Profilmanual Logofarge  Finlandia Green 900x600"/>
                                            </div>
                                        </div>
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="husk">Husk Yellow</h2>
                                                <p>
                                                    PMS: 618<br/>
                                                    CMYK: 14-9-82-28<br/>
                                                    RGB: 174-164-68<br/>
                                                    HTML: AEA444
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_5.jpg" alt="Saga Services Profilmanual Logofarge Husk yellow 900x600"/>
                                            </div>
                                        </div>
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="pickled">Pickled Blue</h2>
                                                <p>
                                                    PMS: 7545<br/>
                                                    CMYK: 58-32-18-54<br/>
                                                    RGB: 66-85-99<br/>
                                                    HTML: 253746
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_6.jpg" alt="Saga Services Profilmanual Logofarge Pickled Blue 900x600"/>
                                            </div>
                                        </div>
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="hippie">Hippie Blue</h2>
                                                <p>
                                                    PMS: 7696<br/>
                                                    CMYK: 56-9-9-21<br/>
                                                    RGB: 99-153-174<br/>
                                                    HTML: 6399AE
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_7.jpg" alt="Saga Services Profilmanual Logofarge Hippie Blue 900x600"/>
                                            </div>
                                        </div>
                                        <div className="row no-gutters spacing-top">
                                            <div className="col-md-5">
                                                <h2 id="dune">Dune Black</h2>
                                                <p>
                                                    PMS: Pro. Black<br/>
                                                    CMYK: 0-0-0-100<br/>
                                                    RGB: 44-42-41<br/>
                                                    HTML: 2C2A29
                                                </p>
                                            </div>
                                            <div className="col-md-7">
                                                <img className="img-fluid" src="../img/sections/farger/farger_8.jpg" alt="Saga Services Profilmanual Logofarge Dune Black 900x600"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </div>
    </Layout>
)}

export default Colors